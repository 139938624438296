const messages = {
  en: {
    appName: 'FydeOS App Store',
    all: 'All',
    byFydeOS: 'Made by FydeOS',
    essentials: 'Essentials',
    extensions: 'Extensions',
    apps: 'Web apps',
    games: 'Games',
    android: 'Android apps',
    education: 'Education',
    themes: 'Themes',
    miniprograms: 'Mini-Programs (β)',
    testApps: 'Test Apps',
    'ext/all': 'All',
    'ext/22-accessibility': 'Accessibility',
    'ext/10-blogging': 'Blogging',
    'ext/1-communication': 'Social & Communication',
    'ext/14-fun': 'Fun',
    'ext/6-news': 'News & Weather',
    'ext/28-photos': 'Photos',
    'ext/7-productivity': 'Productivity',
    'ext/38-search-tools': 'Search Tools',
    'ext/12-shopping': 'Shopping',
    'ext/13-sports': 'Sports',
    'ext/11-web-development': 'Developer Tools',
    'app/all': 'All',
    'app/49-business-tools': 'Businuess Tools',
    'app/8-education': ' Education',
    'app/2-entertainment': 'Entertainment',
    'app/3-game': 'Games',
    'app/5-lifestyle': 'Lifestyle',
    'app/6-news': 'News & Weather',
    'app/7-productivity': 'Productivity',
    'app/1-communication': 'Social & Communication',
    'app/9-utilities': 'Utilities',
    'game/all': 'All',
    'app/31-arcade-action': 'Arcade & Action',
    'app/32-board-card': 'Board & Card',
    'app/35-puzzle-brain': 'Puzzle & Brain',
    'app/34-role-playing-strategy': 'Role-Playing & Strategy',
    'app/37-sports-game': 'Sports Games',
    'app/33-virtual-worlds': 'Virtual Worlds',
    noAppsFind: 'No apps found',
    popular: 'Popular',
    date: 'Date',
    size: 'Size',
    priority: 'Recommendation',
    appTypeTitle: 'Type',
    appCategoryTitle: 'Categories',
    sortBy: 'Sort by',
    orderBy: 'Order',
    search: 'Search',
    resetSearch: 'Reset search results',
    versionTitle: 'Version: ',
    introductionTitle: 'Overview',
    getAppInfoError: 'Can not get app info',
    viewDetails: 'Details',
    ADD_TO_FYDEOS: 'Add',
    ADD_TO_FYDEOS_MINI: 'Add',
    DOWNLOADING: "Downloading",
    DOWNLOADING_MINI: "Cancel",
    INSTALLING: 'Installing',
    INSTALLING_MINI: 'Installing',
    OPEN_APP: 'Open App',
    OPEN_APP_MINI: 'Open',
    NOT_SUPPORT: 'Unsupported OS',
    ANDROID_NOT_ACTIVATED: 'Activate Android',
    ANDROID_NOT_ACTIVATED_MINI: 'Activate',
    activateAndroidHint: 'Click here to activate Android before this action.',
    checkUpdatesTitle: 'Check for Updates',
    checkUpdatesSubtitle: 'These apps can be updated / migrated now. Please make sure to follow the instructions accordingly to update the apps.',
    noUpdates: 'No updates available',
    updateButtonTextToUpdate: 'Update',
    updateButtonTextToUpdating: 'Updating',
    updateButtonTextDidUpdate: 'Open',
    updateButtonTextUpdateFailed: 'Failed',
    checkUpdatesPopoverText1: 'What is migration? This new feature is for migrating your installed apps from Chrome version to FydeOS version. When the migration is complete, automatic app updates will function well even for users in Mainland China.',
    checkUpdatesPopoverText2: 'Note that your local files and preferences related to the apps will not be synchronized after migration. Please make a backup in advance to prevent data loss.',
    copyAppLinkTooltip: 'Click to copy a link to this app',
    wmpfUdpateRequiredTitle: 'Update Required',
    wmpfUdpateRequiredBody: 'Please click "Check for Updates" and update "FydeOS WMPF Helper" to the latest version to launch mini programs.',
    details: 'Details',
    showMore: 'Show More',
    showLess: 'Show Less',
    id: 'ID',
    version: 'Version',
    updatedAt: 'Updated At',
    lastUpdate: 'Last Update',
  },
  zh: {
    appName: 'FydeOS 应用商店',
    all: '全部',
    byFydeOS: '燧炻创造',
    essentials: '必备之选',
    extensions: '浏览器插件',
    apps: '网页应用',
    games: '游戏娱乐',
    android: '安卓程序',
    education: '教育学习',
    themes: '浏览器主题',
    miniprograms: '微信小程序（β）',
    testApps: '测试应用',
    'ext/all': '全部',
    'ext/22-accessibility': '无障碍',
    'ext/10-blogging': '博客',
    'ext/1-communication': '社交与通讯',
    'ext/14-fun': '娱乐',
    'ext/6-news': '新闻与天气',
    'ext/28-photos': '照片',
    'ext/7-productivity': '生产工具',
    'ext/38-search-tools': '搜索工具',
    'ext/12-shopping': '购物',
    'ext/13-sports': '体育',
    'ext/11-web-development': '开发者工具',
    'app/all': '全部',
    'app/49-business-tools': '商业工具',
    'app/8-education': '教育',
    'app/2-entertainment': '娱乐',
    'app/3-game': '游戏',
    'app/5-lifestyle': '生活时尚',
    'app/6-news': '新闻与天气',
    'app/7-productivity': '生产工具',
    'app/1-communication': '社交与通讯',
    'app/9-utilities': '实用工具',
    'game/all': '全部',
    'app/31-arcade-action': '街机和动作类',
    'app/32-board-card': '棋牌',
    'app/35-puzzle-brain': '益智类',
    'app/34-role-playing-strategy': '角色扮演与策略类',
    'app/37-sports-game': '体育游戏',
    'app/33-virtual-worlds': '虚拟世界',
    noAppsFind: '未找到任何应用',
    popular: '热门程度',
    date: '日期',
    size: '大小',
    priority: '推荐',
    appTypeTitle: '类型',
    appCategoryTitle: '类别',
    sortBy: '排序方式',
    orderBy: '顺序',
    search: '搜索',
    resetSearch: '重置搜索结果',
    versionTitle: '版本：',
    introductionTitle: '概述',
    getAppInfoError: '获取应用信息失败',
    viewDetails: '详情',
    ADD_TO_FYDEOS: '添加',
    ADD_TO_FYDEOS_MINI: '添加',
    DOWNLOADING: "正在下载",
    DOWNLOADING_MINI: "取消",
    INSTALLING: '正在安装',
    INSTALLING_MINI: '安装中',
    OPEN_APP: '打开应用',
    OPEN_APP_MINI: '打开',
    NOT_SUPPORT: '不支持的系统',
    ANDROID_NOT_ACTIVATED: '激活安卓',
    ANDROID_NOT_ACTIVATED_MINI: '激活安卓',
    activateAndroidHint: '请点击此处激活安卓，方可执行该操作。',
    checkUpdatesTitle: '检查更新',
    checkUpdatesSubtitle: '以下应用可以开始更新/迁移，请按照提示更新应用。',
    noUpdates: '无可用更新',
    updateButtonTextToUpdate: '更新',
    updateButtonTextToUpdating: '更新中',
    updateButtonTextDidUpdate: '打开',
    updateButtonTextUpdateFailed: '失败',
    checkUpdatesPopoverText1: '何为“迁移”？FydeOS 应用商店中已有数千个应用供用户下载使用。由于这些应用全部来源于 Chrome Web Store，应用的自动更新在中国大陆地区可能无法正常进行。因此我们在应用商店中新增了「检查更新」功能以帮助用户将已安装的 Chrome 应用迁移成 FydeOS 应用。两者的区别仅有一处，即应用的自动更新链接。迁移完成之后，您即可享受应用自动更新的便利。',
    checkUpdatesPopoverText2: '请注意，与您应用相关的本地文件和偏好设置不会在迁移后同步。请事先做好备份，以免造成损失。',
    copyAppLinkTooltip: '点击复制此应用的链接',
    wmpfUdpateRequiredTitle: '必需的更新',
    wmpfUdpateRequiredBody: '请点击「检查更新」并将「FydeOS WMPF 小助手」更新至最新版本以启动小程序。',
    details: '详情',
    showMore: '显示更多',
    showLess: '隐藏',
    id: 'ID',
    version: '版本',
    updatedAt: '更新时间',
    lastUpdate: '上次更新',
  },
};

export default messages;
